if (!lemonade && typeof (require) === 'function') {
    var lemonade = require('lemonadejs');
}

;(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define(factory) :
    global.Router = factory();
}(this, (function () {

    /**
     * Create a DIV container
     */
    const div = function() {
        return document.createElement('div');
    }

    /**
     * Basic handler
     * @param h - HTML
     * @returns lemonade.element
     */
    const Base = function(h) {
        return lemonade.element(h, this);
    }

    const getComponent = function(controller, components) {
        if (controller) {
            if (typeof(controller) === 'function') {
                return controller;
            } else {
                let t = document.lemonadejs.components[controller.toUpperCase()];
                if (t) {
                    return t;
                } else {
                    t = components[controller]
                    if (t) {
                        return t;
                    }
                }
            }
        }
        return Base;
    }

    const getAttributeName = function(prop) {
        if (prop.substring(0,1) === ':') {
            prop = prop.substring(1);
        } else if (prop.substring(0,3) === 'lm-') {
            prop = prop.substring(3);
        }
        return prop.toLowerCase();
    }

    const extractFromHtml =  function(element, components) {
        if (element.children.length) {
            // Location
            let loc = window.location;
            // Nodes to be removed
            let r = [];
            for (let i = 0; i < element.children.length; i++) {
                let o = {};
                // Push to the configuration
                this.config.push(o);
                // Dom element
                let e = element.children[i];
                // Temporary attributes from the entry
                let t = e.attributes;
                for (let j = 0; j < t.length; j++) {
                    let name = getAttributeName(t[j].name);
                    o[name] = t[j].value;
                }
                if (e.controller) {
                    o.controller = e.controller;
                }
                // Depending on how you declare
                if (! o.controller && e.tagName === 'DIV') {
                    o.element = e;
                    o.element.classList.add('lm-page');
                    o.self = { parent: this };

                    if (! this.current) {
                        this.current = o;
                        // Register path for this page that is already loaded to the DOM
                        if (! o.path) {
                            o.path = loc.pathname + loc.search;
                        }
                        // Current path
                        this.path = o.path;
                    } else {
                        r.push(e);
                    }
                } else {
                    // Controller
                    o.controller = getComponent(o.controller, components);
                    // Remove
                    r.push(e);
                }
            }
            if (r.length) {
                while (r[0]) {
                    r.shift().remove();
                }
            }
        }
    }

    const extract = function(children, components) {
        if (! this.config) {
            this.config = [];
        }

        if (this.tagName) {
            extractFromHtml.call(this, this, components);
        } else {
            // Get data
            if (typeof(children) === 'string') {
                // Version 4
                let d = document.createElement('div');
                d.innerHTML = children;
                extractFromHtml.call(this, d, components);
            } else if (children && children.length) {
                // Version 5
                children.forEach((v) => {
                    let item = {}
                    v.props.forEach((prop) => {
                        item[getAttributeName(prop.name)] = prop.value;
                    });
                    this.config.push(item);
                });
                // Block children
                children.length = 0;
            }
        }
    }

    // Create DOM element and process events
    let createPage = function(page, cb, html) {
        let self = this;

        // Events
        if (typeof(self.onbeforecreatepage) === 'function') {
            let ret = self.onbeforecreatepage.call(self, page, html);
            if (ret === false) {
                return false;
            }
        }

        // Create the self and make that available on the route configuration
        let s = page.self = { parent: self };
        // Create element container
        let e = div();
        e.classList.add('lm-page');
        // Hide that
        e.style.display = 'none';
        // Add the element to the configuration
        page.element = e;
        // Temp
        let t = null;
        if (! self.single) {
            // Make sure the order is correct
            for (let i = 0; i < self.config.length; i++) {
                t = self.config[i].element;
                if (t) {
                    self.el.appendChild(t);
                }
            }
        }

        // Controller
        let handler = page.controller;
        if (handler) {
            lemonade.render(handler, e, s, html);
        } else if (html) {
            e.innerHTML = html;
        }

        if (cb) {
            cb.call(self, page, true);
        }
    }

    /**
     * Create new page
     */
    const create = function(page, cb, signal) {
        let self = this;

        if (page.url) {
            // Fetch a remote view
            let u = page.url;
            if (u.indexOf('?') === -1) {
                u += '?dt='
            } else {
                u += '&dt=';
            }
            // Headers
            const request = {
                headers: {
                    'Accept': 'text/html',
                    'X-Requested-With': 'http'
                },
                signal: signal
            }
            // Fetch a remote content
            fetch(u + new Date().getTime(), request)
                .then(function(response) {
                    // Something went wrong
                    if (! response.ok) {
                        throw new Error('Something went wrong');
                    }
                    // Convert the result in HTML
                    return response.text();
                })
                .then(function(html) {
                    // Content is ready, create the page
                    createPage.call(self, page, cb, html);
                }).catch(() => {});
        } else {
            createPage.call(self, page, cb);
        }
    }

    const load = function(page, newPage, callback) {
        // Hide old element
        if (! this.current || page.element !== this.current.element) {
            if (this.current && ! this.single && this.animation) {
                // Show element for the animation
                page.element.style.display = '';
                // Start the animation
                animation.call(this, page, newPage, callback);
            } else {
                changePage.call(this, page, newPage, callback);
            }
        } else {
            // Loading animation
            this.el.classList.remove('lm-loading');
        }
    }

    let animationTimer = null;
    let animationCallback = null;

    const animation = function(page, newPage, callback) {
        let self = this;
        // Correct class
        let e = self.el.classList;
        let d = 'lm-slide-left-' + (self.config.indexOf(page) < self.config.indexOf(self.current) ? 'in' : 'out');
        e.add(d);

        if (animationTimer) {
            // Timer
            clearTimeout(animationTimer);
            animationCallback();
        }

        animationCallback = function() {
            // Remove animation
            e.remove(d);
            // Change page
            changePage.call(self, page, newPage, callback);
            // Timer
            animationTimer = null;
        }

        animationTimer = setTimeout(animationCallback, 400);
    }

    /**
     * @param {object} page - new page object
     * @param {boolean} isNewPage - this is a new page added
     * @param callback
     */
    const changePage = function(page, isNewPage, callback) {
        // Hide previous
        if (this.single) {
            if (this.current) {
                this.current.element.remove();
            }
            this.el.appendChild(page.element);
        } else {
            if (this.current) {
                this.current.element.style.display = 'none';
            }
        }
        // Page
        let oldPage = this.current;
        // Make sure the new page is visible
        page.element.style.display = '';
        // On enter
        if (oldPage) {
            if (oldPage.self.onleave) {
                oldPage.self.onleave.call(this, oldPage, page);
            }
        }
        // Current page
        this.current = page;
        // On enter
        if (page.self.onenter) {
            page.self.onenter.call(this, page, oldPage);
        }
        // Remove loading animation
        this.el.classList.remove('lm-loading');

        // Callback
        callback();

        // Onchange
        if (typeof(this.onchangepage) === 'function') {
            this.onchangepage.call(this, page, oldPage, isNewPage);
        }
    }

    /**
     * Get route
     * @param {string} p - pathname
     */
    const getConfig = function(p) {
        let c = this.config;
        for (let i = 0; i < c.length; i++) {
            if (p === c[i].path || p.match(new RegExp('^'+c[i].path+'$', 'gi'))) {
                return c[i];
            }
        }
        return false;
    }

    const Router = function(children, components) {
        let self = this;

        // Extra configuration
        extract.call(this, children, components);

        self.onload = function() {
            // Pre load
            let c = self.config;
            if (c) {
                for (let i = 0; i < c.length; i++) {
                    if (c[i].preload) {
                        create.call(self, c[i]);
                    }
                }
                // Current address
                let a = window.location;
                // Set the initial path
                self.setPath(a.pathname + a.search, true);
            }
        }

        // Control the request for an external page
        let currentController = null;

        self.setPath = function(path, ignore) {
            // Get the configuration based on the path
            let page = getConfig.call(self, path);
            // Configuration
            if (typeof(self.onbeforechangepage) === 'function') {
                let r = self.onbeforechangepage.call(self, path, page);
                if (r === false) {
                    return;
                } else if (r) {
                    if (typeof(r) == 'object') {
                        page = r;
                    } else {
                        path = r;
                        page = getConfig.call(self, path);
                    }
                }
            }

            // Check if there's an ongoing fetch and abort it
            if (currentController) {
                currentController.abort();
                // Abort
                currentController = null;
                // Abort previous ongoing call
                self.el.classList.remove('lm-loading');
            }

            if (page && path !== self.path) {
                // Loading animation
                self.el.classList.add('lm-loading');

                let cb = function(a, b) {
                    // Page is ready
                    load.call(self, a, b, function() {
                        // Get the title from h1
                        let h1 = self.el.querySelector('h1');
                        // Title
                        document.title = h1 ? h1.innerText : '';
                        // Check test
                        if (! ignore) {
                            history.pushState({ route: path }, '', path);
                        }
                        // Set the new path
                        self.path = path;
                        // Done
                        self.el.classList.remove('lm-loading');
                        // Signal
                        currentController = null;
                    });
                }

                // Render new page based on the configuration found for this path
                if (! page.element) {
                    // Create an instance of AbortController
                    currentController = new AbortController();
                    // Create new page
                    create.call(self, page, cb, currentController.signal);
                } else {
                    cb(page, false);
                }
            }

            // Return the page object
            return page;
        }

        // Intercept click
        document.body.addEventListener('click', function(e) {
            let a = e.target.closest('a');
            if (a && a.tagName === 'A' && a.href && ! a.getAttribute('target') && a.getAttribute('href').indexOf('http') !== 0 && a.getAttribute('href').indexOf('#') === -1) {
                self.setPath(a.pathname + a.search);
                e.preventDefault();
            }
        });

        // Events
        window.addEventListener('popstate', function(e) {
            let a = window.location;
            self.setPath(a.pathname + a.search, true);
        });

        return `<div class="lm-pages"></div>`;
    }

    // Create the LemonadeJS component
    lemonade.setComponents({ Router: Router });
    // Register the web component
    lemonade.createWebComponent('router', Router, { applyOnly: true });

    return function(root, options, components) {
        try {
            // Render element
            if (typeof(root) === 'object') {
                // Do not accept blank options
                if (! options) {
                    options = {};
                }
                if (! options.config) {
                    options.config = [];
                }
                // Extra any configuration from the HTML
                extractFromHtml.call(options, root, components);
                // Pages class
                root.classList.add('lm-pages');
                // Element
                options.el = root;
                // Create configuration
                Router.call(options);
                // Load configurations
                options.onload();
                return options;
            } else {
                // Render element
                return Router.call(this, root, components);
            }
        } catch (e) {
            console.error(e)
        }
    }
})));
